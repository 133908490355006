@charset "utf-8";
//フッター

.footer_out{
  .footer_contact{
    background-image: url(/assets/images/common/contact_back.jpg);
    background-repeat: no-repeat;
    background-position: top right;
    padding-bottom: 120px;
    padding-top: 100px;
    margin-bottom: 80px;
    background-size: cover;
    @media #{$size_1000}{
      padding-bottom: 60px;
      padding-top: 80px;
    }
    color:#fff;
    a{
      color:#fff;
    }

    .inner{
      .h2_title02--f_contact{
        margin-bottom: 50px;
        @media #{$size_1000}{
          margin-bottom: 30px;
        }
      }
      .text01{
        margin-bottom: 17px;
        @include x03;
      }
      .footer_tel{
        font-style: italic;
        margin-bottom: 23px;
        @include lora;
        span{
          &:first-child{
            @include fontsize(36);
            @media #{$size_1000}{
              @include fontsize(24);
            }
          }
          &:nth-child(2){
            @include fontsize(48);
            @media #{$size_1000}{
              @include fontsize(28);
            }
          }
        }
      }
      .footer_contact_btn{
        text-align: center;
        width: 100%;
        ul{
          max-width: 735px;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @media #{$size_1000}{
            display: block;
          }
          li{
            width: 50%;
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;
            @include fontsize(18);
            @media #{$size_1000}{
              width: 100%;
              margin-bottom: 30px;
              max-width: 280px;
              margin: 0 auto 30px auto;
              padding-left: 0;
              padding-right: 0;

            }
            a{
              width: 100%;
              display: block;
              height: 68px;
              line-height: 68px;
              @include x03;
            }
            &:first-child{
              a{
                background-color: #e08a94;
                @include transition0-3;
                &:hover{
                  background-color: #5d3035;
                }
              }
            }
            &:nth-child(2){
              a{
                background-color: #d5ac51;
                @include transition0-3;
                &:hover{
                  background-color: #aa8b3e;
                }
              }
            }
          }
        }
      }
    }
  }//footer_contact
  .footer_link01{
    padding-top: 60px;
    padding-bottom: 60px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    @include bcg01;
    display: none;
    .inner{
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;

      ul{
        max-width: 1000px;
        display: flex;
        flex-direction: row;
        margin-left: -20px;
        margin-right: -20px;
        @media #{$size_1000}{
          display: block;
          margin-left: 0;
          margin-right: 0;
        }
        li{
          width: 50%;
          padding-left: 20px;
          padding-right: 20px;
          box-sizing: border-box;
          @media #{$size_1000}{
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 30px;
            max-width: 400px;
          }
          a{
            display: block;
            position: relative;
            transform: scale(1);
            overflow: hidden;
            z-index: 0;
            &:after{
              display: block;
              content:"";
              width: 100%;
              height: 100%;
              z-index: 1;
              background-color: #000;
              opacity: 0;
              position: absolute;
              transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) ;
              top: 0;
              left: 0;
            }
            img{
              width: 100%;
              transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) ;
              position: relative;
              z-index: 0;
            }
            span{
              position: relative;
              z-index: 0;
              &:nth-child(2){
                position: absolute;
                top: calc(50% - 10px);
                line-height: 1.2;
                left: 0;
                right: 0;
                @include fontsize(20);
                color:#fff;
                letter-spacing: 0.2em;
                z-index: 2;

              }
            }
            &:hover{
              img{
                transform: scale(1.1);
                transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
              }
              &:after{
                transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
                opacity: 0.6;
              }
            }
          }//a

        }
      }
    }
  }

  .footer_box01{
    background-color: #2b2b2b;
    color:#fff;
    padding-top: 80px;
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;

    a{
      color:#fff;
    }
    .inner{
      .footer_logo{
        text-align: center;
        margin-bottom: 19px;
        a{
          display: block;
          margin: 0 auto;
          width: 110px;
          img{
            width: 100px;
            opacity: 0.8;
            @include transition0-3;
          }
          &:hover{
            img{
              opacity: 1;
              @include transition0-3;
            }
          }
        }
      }
      .add{
        @include fontsize(13);
        opacity: 0.6;
        margin-bottom: 40px;
        @include x03;
      }
      .footer_nav{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 30px;
        @media #{$size_1000}{
          flex-wrap: wrap;
          max-width: 500px;
          margin: 0 auto 40px auto;
          @media #{$size_400}{
            max-width: 250px;
          }
        }
        ul{
          text-align: left;
          @media #{$size_1000}{
            width: 50%;
            padding-left: 50px;
            box-sizing: border-box;
          }
          @media #{$size_400}{
            width: 100%;
          }
          li{
            margin-bottom: 20px;
            a{
              span{
                color:#fff;
                @include fontsize(14);
                opacity: 0.6;
                @include transition0-3;
                @include x03;
              }
              &:hover{
                span{
                  @include transition0-3;
                  opacity: 1;
                }
              }
            }
          }
        }
      }//footer_nav
      .footer_nav02{
        p{
          opacity: 0.6;
          @include x03;
        }
        a{
          opacity: 0.6;
          @include x03;
          &:hover{
            opacity: 1;
          }
        }
        .inner{
          padding-bottom: 60px;
          p{
            margin-bottom: 20px;
          }
          ul{
            display: inline-block;
            overflow: hidden;
            li{
              float: left;
              padding-left: 20px;
              padding-right: 20px;
              a{
                @include x03;
              }
            }
          }
        }
      }//footer_nav02
      .copy{
        @include x01;
        @include lora;
        opacity: 0.6;
        @include fontsize(14);
        letter-spacing: 0.1em;
        @media #{$size_1000}{
          @include fontsize(10);
        }
      }
    }
  }
  .pagetop{
    position: fixed;
    z-index: 80;
    bottom: 30px;
    right: 30px;
    width: 44px;
    height: 44px;
    opacity: 0;
    @include transition01;
    a{
      background-color: #d38891;
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      @include transition01;
      &:hover{
        background-color: #5d3035;
        @include transition01;
      }
      img{
        width: 28px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 14px;
      }
    }
  }
  .pt_opacity01{
    opacity: 1;
    @include transition01;
  }
  .pt_opacity00{
    opacity: 0;
    @include transition01;
  }


}//footer_out
