@charset "utf-8";

.header_out_detail{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999990;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  height: 70px;
  background-color: #fff;
  @media #{$size_1000}{
    padding-left: 15px;
    padding-right: 15px;
  }
  .inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    .left{
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_logo_out{
        a{
          line-height: 1.2;
          display: inline-block;
          @include transition01;
          opacity: 0.8;
          &:hover{
            opacity: 1;
            @include transition01;
          }
          img{
            width: 140px;
            @media #{$size_1000}{
              padding-top: 7px;
              width: 100px;
            }
          }
        }
      }
      .link01{
        padding-left: 55px;
        @include transition01;
        @include x03;
        &:hover{
          opacity: 0.6;
          @include transition01;

        }
      }
    }
    .right{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 65px;
      @media #{$size_1000}{
        padding-right: 50px;
      }
      .tel{
        @include fontsize(18);
        @include lora;
        margin-right: 27px;
        @media #{$size_1000}{
          display: none;
        }
      }
      .tel_sp{
        margin-right: 10px;
        a{
          width: 30px;
          height: 30px;
          padding-top: 2px;
          opacity: 0.5;
          display: block;
          img{
            width: 100%;
          }
        }
        @media #{$size_1000}{
          display: block;

        }
      }
      .access{
        margin-right: 30px;
      }
      .header_fair_btn{
        display: block;
        width: 100px;
        height: 36px;
        background-color: #d5ac51;
        line-height: 36px;
        @include fontsize(14);
        @include x03;
        span{
          color:#fff;
        }
        &:hover{
          background-color: #aa8b3e;
          @include transition0-3;
        }
        @media #{$size_1000}{
          @include fontsize(11);
          width: auto;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}

.toppage_body{
  .header_out_detail,
  .smp_nav_btn{
    transform: translateY(-70px);
    @media #{$size_1000}{
      transform: translateY(0px);
    }
  }

  .header_out_detail_animation,
  .smp_nav_btn_animation
  {
    transform: translateY(0px);
    @include transition0-3;
  }
  .header_out_detail_animation_remove,
  .smp_nav_btn_animation_remove
  {
    transform: translateY(-70px);
    @include transition0-3;
  }
}
