@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Tangerine');
@mixin ptf01{
  font-family: 'Tangerine', cursive;
}

.pattern_scroll_down--pc{
  display: block;
  @media #{$size_1000}{
    display: none;
  }
}
.pattern_scroll_down--sp{
  display: none;
  @media #{$size_1000}{
    display: block;
  }
}

.photo_t_main{//メインイメージ
  background-image: url(/assets/images/photo-tours/main.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position:left bottom;
  width: 100%;
  height: calc(100vh - 70px);
  color:#fff;
  position: relative;
  top: 0;
  left: 0;
  z-index: 50;
  a{
    color:#fff;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .inner{
    p{
      @include ptf01;
      @include fontsize(200);
      line-height: 1.2;
      margin-bottom: 20px;
      @media #{$size_1500}{
        @include fontsize(100);
      }
      @media #{$size_1000}{
        @include fontsize(60);
      }
    }
    h1{
      @include fontsize(18);
      letter-spacing: 0.6em;
      padding-bottom: 140px;
    }
  }
  .scroll_down{
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin:auto;
    z-index: 1;
    display: block;
    .arrow{

    }
    .text{
      padding-top: 29px;
      @include x02;
      letter-spacing: 0.2em;
      @include fontsize(14);
    }
  }
}



@mixin pt_h3_title{
  @include ptf01;
  color:#eeb4b4;
  @include fontsize(180);
  line-height: 1.2;
  text-align: left;
  @media #{$size_1500}{
    @include fontsize(150);
  }
  @media #{$size_1000}{
    @include fontsize(60);
  }
}
.pt_out00{
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  width: 100%;
  @media #{$size_1000}{
    padding-left: 0;
    padding-right: 0;
  }
}


//タイトル左側
@mixin pt_l_title{
  padding-bottom: 200px;
  padding-top: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  @media #{$size_1000}{
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 0px;
    height: auto;
    min-height: auto;
    padding-bottom: 0;
    position: static;
  }
  .inner{
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 auto;
    text-align: left;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    @media #{$size_1500}{
      max-width: 1000px;
    }
    @media #{$size_1000}{
      display: block;
    }
    .right{
      padding-left: 80px;
      width: 38%;
      box-sizing: border-box;
      /*position: fixed;
      z-index: 1;
      top: -50%;
      right: 50%;
      transform: translateX(-50%) translateY(-50%);*/
      @media #{$size_1500}{
        width: 50%;
      }
      @media #{$size_1000}{
        width: 100%;
        padding-left: 0;
        max-width: 500px;
        margin: 0 auto;
      }
      h3{
        @include pt_h3_title;
        margin-left: -128px;
        margin-bottom: 10px;
        @media #{$size_1500}{
          margin-left: -80px;
          margin-bottom: 20px;
        }
        @media #{$size_1000}{
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
      p{
        text-align: left;
        @include fontsize(18);
        line-height: 2.2;
        letter-spacing: 0;
        margin-bottom: 40px;
        @include x03;
        @media #{$size_1000}{
          margin-bottom: 30px;
          @include fontsize(16);
          line-height: 1.8;
        }
      }
      .btn_out{
        text-align: left;
        a{
          @media #{$size_1000}{

          }
        }
      }
    }
  }//inner
}


//タイトル右側
@mixin pt_r_title{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 200px;
  box-sizing: border-box;
  @media #{$size_1000}{
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 0px;
    height: auto;
    position: static;
  }
  .inner{
    max-width: 1400px;
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    margin: 0 auto;
    text-align: left;
    box-sizing: border-box;
    @media #{$size_1500}{
      max-width: 1000px;
    }
    @media #{$size_1000}{
      display: block;
    }
    .right{
      padding-right: 80px;
      width: 38%;
      box-sizing: border-box;
      @media #{$size_1500}{
        width: 50%;
      }
      @media #{$size_1000}{
        width: 100%;
        padding-right: 0;
        max-width: 500px;
        margin: 0 auto;
      }
      h3{
        @include pt_h3_title;
        margin-bottom: 10px;
        @media #{$size_1500}{
          margin-bottom: 20px;
        }
        @media #{$size_1000}{
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
      p{
        text-align: left;
        @include fontsize(18);
        line-height: 2.2;
        letter-spacing: 0;
        margin-bottom: 40px;
        @include x03;
        @media #{$size_1000}{
          margin-bottom: 30px;
          @include fontsize(16);
          line-height: 1.8;
        }
      }
      .btn_out{
        text-align: left;
        a{
          margin: 0;
          @media #{$size_1000}{

          }
        }
      }
    }
  }//inner
}

//写真左側
@mixin pt_l_pic{
  padding-bottom: 300px;
  padding-top: 300px;
  width: 100%;
  overflow: hidden;
  @media #{$size_1000}{
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .inner{
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    text-align: left;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    @media #{$size_1500}{
      max-width: 1000px;
    }
    @media #{$size_1000}{
      display: block;
      padding-top: 0;
    }
    .left{
      width: 62%;
      @media #{$size_1500}{
        width: 50%;
      }
      @media #{$size_1000}{
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
      }
      img{
        width: 100%;
        box-sizing: border-box;
      }
      span{
        margin-bottom: 60px;
        display: block;
        @media #{$size_1000}{
          margin-bottom: 0px;
        }
      }
    }
  }//inner
}

//写真右側
@mixin pt_r_pic{
  padding-bottom: 300px;
  padding-top: 300px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  @media #{$size_1000}{
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .inner{
    max-width: 1400px;
    display: flex;
    flex-direction:row-reverse;
    margin: 0 auto;
    text-align: left;
    box-sizing: border-box;
    @media #{$size_1500}{
      max-width: 1000px;
    }
    @media #{$size_1000}{
      display: block;
      padding-top: 0;
    }
    .left{
      width: 62%;
      box-sizing: border-box;
      @media #{$size_1500}{
        width: 50%;
      }
      @media #{$size_1500}{
        width: 50%;
      }
      @media #{$size_1000}{
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
      }
      img{
        width: 100%;
      }
      span{
        display: block;
        margin-bottom: 60px;
        @media #{$size_1000}{
          margin-bottom: 0px;
        }
      }
    }
    .right{
      padding-right: 80px;
      width: 38%;
      box-sizing: border-box;
      @media #{$size_1500}{
        width: 50%;
      }
      h3{
        @include pt_h3_title;
        margin-bottom: 36px;
      }
      p{
        text-align: left;
        @include fontsize(18);
        line-height: 2.2;
        letter-spacing: 0;
        margin-bottom: 70px;
      }
      .btn_out{
        text-align: left;
        a{
          margin: 0;
        }
      }
    }
  }//inner
}




//斜め線
.ph_box01{
  box-sizing: border-box;
  margin: 0 auto;
  position: fixed;
  z-index: -1;
  width: 100%;
  height:100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:before{
    content:"";
    display: block;
    //@include bcg01;
    background-color: #f6f5ec;
    width: 100%;
    height: 500px;
    transform: skewY(-15deg);
    position: relative;
    z-index: -1;

  }
}





///////////////////////01

.ph_box01--01--title_out{
  position: relative;
  .ph_box01--01--title{
    @include pt_l_title;
  }
}

.ph_box01--01{
  @include pt_l_pic;
}

////////////////////////////02

.ph_box01--02--title_out{
  position: relative;
  .ph_box01--02--title{
    @include pt_r_title;
  }
}

.ph_box01--02{
  @include pt_r_pic;
}

///////////////////////////03

.ph_box02--01--title_out{
  position: relative;
  .ph_box02--01--title{
    @include pt_l_title;
  }
}

.ph_box02--01{
  @include pt_l_pic;
}

///////////////////////////04

.ph_box03--02--title_out{
  position: relative;
  .ph_box03--02--title{
    @include pt_r_title;
  }
}

.ph_box03--02{
  @include pt_r_pic;
}


.pt_last_content{
  width: 100%;
  position: relative;
  z-index: 50;
  .text01{
    position: absolute;
    top: 90px;
    left: 90px;
    z-index: 1;
    text-align: left;
    @include fontsize(36);
    @media #{$size_1200}{
      @include fontsize(24);
      top: 30px;
      left: 30px;
    }
    @media #{$size_1000}{
      bottom: 30px;
      top:auto;
      background-color: rgba(#fff,0.6);
      padding-left: 15px;
      padding-right: 15px;
    }
    @media #{$size_600}{
      position: static;
      padding-top: 15px;
    }
  }
  .pic{
    position: relative;
    z-index: 0;
    img{
      width: 100%;
    }
  }
}

.pt_last_content_text{
  padding-top: 190px;
  padding-bottom: 180px;
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  @media #{$size_1000}{
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .text_out{
    @media #{$size_1000}{
    }
    p{
      @include fontsize(18);
      margin-bottom: 22px;
      letter-spacing: 0.2em;
      @include x03;
      @media #{$size_1000}{
        @include fontsize(16);
        margin-bottom: 0px;
        letter-spacing: 0em;
        text-align: left;
        display: inline;
      }
    }
  }
}

.photo_tours_body{
  .footer_out{
    position: relative;
    z-index: 40;
  }
}
