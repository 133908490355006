@charset "utf-8";
.top_main_img{
  width:100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  @media #{$size_1000}{
    height: auto;
  }
  &:before{
    background: linear-gradient(0deg, rgba(#fff, 0) 0%,rgba(#fff,0.8) 70%, #fff 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 150px;
    opacity: 1;
    @media #{$size_1000}{
      display: none;
    }
  }
  .inner01{//スライダー
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin:auto;
    z-index: 0;
    transform-origin: center;
    overflow: hidden;
    @media #{$size_1000}{
      position: static;

    }
    .zs-enabled{
      .zs-slideshow{
        .zs-slides{
          .zs-slide-0{
            @media #{$size_1000}{
              background-image: url(/assets/images/top/sp_main01.jpg) !important;
              background-position: center bottom;
            }
          }
          .zs-slide-1{
            background-position: left bottom;
            @media #{$size_1000}{
              background-image:
              url(/assets/images/top/sp_main02.jpg) !important;
              background-position: center bottom;
            }
          }
          .zs-slide-2{
            background-position: right bottom;
            @media #{$size_1000}{
              background-position: center bottom;
              background-image:
              url(/assets/images/top/sp_main03.jpg) !important;
            }
          }
        }
      }
    }
  }
  .inner02{//メインイメージテキスト
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    margin:auto;
    @media #{$size_1000}{
      position: static;
    }
    .box01{
      background-color: rgba(255,255,255,0.8);
      display: inline-block;
      padding-top: 35px;
      padding-left: 60px;
      padding-right: 60px;
      padding-bottom: 30px;
      @media #{$size_1000}{
        padding-top: 30px;
        padding-bottom: 40px;
        padding-left: 0;
        padding-right: 0;
      }
      p{
        @include fontsize(42);
        @include lora;
        color:#e77b88;
        letter-spacing: 0.07em;
        letter-spacing: 0.1em;
        margin-bottom: 11px;
        br{
          display: none;
        }
        @media #{$size_1500}{
          @include fontsize(36);
        }
        @media #{$size_1000}{
          @include fontsize(28);
          br{
            display: block;
          }
        }
      }
      h1{
        @include fontsize(18);
        margin-bottom: 23px;
        @include x03;
        br{
          display: none;
          @media #{$size_1000}{
            display: block;
          }
        }
      }
    }
  }
  .inner03{
    background-color: rgba(255,255,255,0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
    text-align: center;
    height: 60px;
    @media #{$size_1000}{
      display: none;
    }
    .box02{
      height: 100%;
      max-width: 1200px;
      margin: 0 auto;
      ul{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
        align-items: center;
        height: 100%;
        li{
          a{
            position: relative;
            @include x03;
            &:before{
              content:"";
              display: block;
              width: 100%;
              height: 1px;
              position: absolute;
              bottom: -3px;
              left: 0;
              right: 0;
              margin: auto;
              background-color: #999;
              transform: scaleX(0);
              @include transition02;
              opacity: 0;
            }
            &:hover{
              &:before{
                transform: scaleX(1);
                @include transition02;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.fair_content01{
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: row;
  background-image: url(/assets/images/top/back01.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 602px;
  @media #{$size_1000}{
    min-height: auto;
    padding-bottom: 60px;
    background-size: 300px;
  }
  &:after{
    content:"";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 43.69%;
    z-index: -1;
    background-color: #ffced3;
    height: 100%;
    display: block;
    @media #{$size_1000}{
      display: none;
    }
  }

  .inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-top: 300px;
    padding-bottom: 300px;
    @media #{$size_1000}{
      display: block;
      padding-top: 0;
      padding-bottom: 0;
    }
    .left,.right{
      width: 50%;
      @media #{$size_1000}{
        width: 100%;
      }
    }
    .left{
      @media #{$size_1000}{
        padding-top: 80px;
        background-color: #ffced3;
        padding-bottom: 80px;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;
      }
      .left_inner{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        @media #{$size_1000}{
          display: block;
        }
        p{
          @include lora;
          color:#fff;
          @include fontsize(150);
          position: absolute;
          top: -150px;
          right: 150px;
          white-space: nowrap;
          z-index: 1;
          @media #{$size_1800}{
            @include fontsize(120);
            top: -120px;
          }
          @media #{$size_1500}{
            @include fontsize(100);
            top: -100px;
          }
          @media #{$size_1300}{
            @include fontsize(70);
            top: -60px;
          }
          @media #{$size_1000}{
            right: auto;
            left: 60px;
            top: -60px;
          }
          @media #{$size_800}{
            left: 0;
            right: 0;
            margin: auto;
            top: -40px;
            @include fontsize(45);

          }
        }
        .pic01{
          max-width: 600px;
          width: 100%;
          position: relative;
          z-index: 0;
          @media #{$size_1500}{
            max-width: 500px;
          }
          @media #{$size_1300}{
            max-width: 400px;
          }
          @media #{$size_1000}{
            max-width: 500px;
            margin: 0 auto;
          }

          &:after{
            position: absolute;
            content: "";
            display: block;
            background-color: #fff;
            width: 100%;
            height: 100%;
            left: -60px;
            bottom: -60px;
            z-index: -1;
            @media #{$size_1000}{
              left: -30px;
              bottom: -30px;
            }
          }
          img{
            width: 100%;
          }
        }
      }

    }
    .right{
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;
      @media #{$size_1000}{
        padding-top: 50px;
        display: block;
        padding-bottom: 80px;

      }
      .right_inner{
        max-width: 470px;
        margin-left: 116px;

        @media #{$size_1500}{
          max-width: 400px;
          margin-left: 60px;
        }
        @media #{$size_1000}{
          max-width: 500px;
          margin: 0 auto;
        }
        .text01{
          @include fontsize(20);
          letter-spacing:0.02em ;
          background-color: #ffeef0;
          width: 214px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          margin-bottom: 20px;
          @media #{$size_1000}{
            @include fontsize(14);
          }
        }
        .text02{
          @include fontsize(28);
          line-height: 1.7;
          letter-spacing:0.02em ;
          margin-bottom: 22px;
          @media #{$size_1000}{
            @include fontsize(18);
          }
        }
        .text03{
          @include fontsize(20);
          letter-spacing:0.02em ;
          margin-bottom: 15px;
          @media #{$size_1000}{
            @include fontsize(16);
          }
        }
        .text04{
          @include fontsize(16);
          line-height: 1.8;
          letter-spacing:0.02em ;
          margin-bottom: 50px;
          @include x03;
          @media #{$size_1000}{
            margin-bottom: 30px;
          }
        }
        .btn_out{
          text-align: left;
          @media #{$size_1000}{
            text-align: center;
          }
          a{
            margin: 0;
            @media #{$size_1000}{
              margin: 0 auto;
            }
          }
        }
      }

    }//.right

  }//.inner
  .fair_list_link{
    position: absolute;
    bottom: 60px;
    right: 60px;
    @media #{$size_1000}{
      right: 0;
      left: 0;
      margin: auto;
    }
    a{
      @include fontsize(20);
      position: relative;
      display: inline-block;
      &:after{
        position: absolute;
        content: "";
        display: block;
        background-color: #e08a94;
        width: 100%;
        height: 1px;
        bottom: -8px;
        z-index: -1;
        @include transition0-3;
      }
      &:hover{
        &:after{
          transform: scaleX(0);
          opacity: 0;
          @include transition0-3;
        }
      }

    }
  }
}//fair_content01

.top_ceremony{
  height: 1080px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  @media #{$size_1200}{
    min-height: auto;
    height: auto;
  }
  @media #{$size_1000}{
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  &:before{
    content:"";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/assets/images/top/pic03.jpg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    @media #{$size_1000}{
      position: static;
      height: calc(100vw * 0.7);
    }
  }

  .inner{
    width: 100%;
    .left{
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media #{$size_1200}{
        margin-left: -30px;
        width: calc(50% + 30px);
      }
      @media #{$size_1000}{
        display: block;
        width: 100%;
        margin-left: 0;
      }

      .text_box{
        background-color: rgba(255,255,255,0.9);
        padding-left: 64px;
        padding-right: 64px;
        padding-top: 93px;
        padding-bottom: 102px;
        box-sizing: border-box;
        @media #{$size_1200}{
          width: 100%;
        }
        @media #{$size_1000}{
          padding-top: 40px;
          padding-bottom: 60px;
          padding-left: 30px;
          padding-right: 30px;
        }
        .h2_title01{
          margin-bottom: 24px;
          h2{
            @include lora;
          }
          @media #{$size_1000}{
            margin-bottom: 15px;
          }
        }
        .text01{
          line-height: 2.2;
          letter-spacing: 0.02em;
          margin-bottom: 53px;
          @include x03;
          @media #{$size_1000}{
            line-height: 1.8;
            letter-spacing: 0;
          }
          @media #{$size_500}{
            text-align: left;
            margin-bottom: 30px;
            br{
              display: none;
            }
          }

        }
      }
    }
  }
}//top_ceremony

.top_party{

  height: 1080px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  @media #{$size_1200}{
    height: auto;
  }
  @media #{$size_1000}{
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  &:before{
    content:"";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(/assets/images/top/pic03.jpg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    background-image: url(/assets/images/top/pic04.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$size_1000}{
      position: static;
      height: calc(100vw * 0.7);
    }
  }
  .inner{
    width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .right{
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;    display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media #{$size_1200}{
        margin-right: -30px;
        width: calc(50% + 30px);
      }
      @media #{$size_1000}{
        display: block;
        width: 100%;
        margin-right: 0;
      }
      .text_box{
        background-color: rgba(255,255,255,0.9);
        padding-left: 64px;
        padding-right: 64px;
        padding-top: 93px;
        padding-bottom: 102px;
        box-sizing: border-box;
        @media #{$size_1200}{
          width: 100%;
        }
        @media #{$size_1000}{
          padding-top: 40px;
          padding-bottom: 60px;
          padding-left: 30px;
          padding-right: 30px;
        }
        .h2_title01{
          margin-bottom: 24px;
          h2{
            @include lora;
          }
          @media #{$size_1000}{
            margin-bottom: 15px;
          }
        }
        .text01{
          line-height: 2.2;
          letter-spacing: 0.02em;
          margin-bottom: 53px;
          @include x03;
          @media #{$size_1000}{
            line-height: 1.8;
            letter-spacing: 0;
          }
          @media #{$size_500}{
            text-align: left;
            margin-bottom: 30px;
            br{
              display: none;
            }
          }
        }
      }
    }
  }
}//top_party

.top_plan{
  @include section_padding;
  @include bcg01;
  @media #{$size_1000}{
    padding-left: 0;
    padding-right: 0;
  }
  .inner{
    .h2_title02{
      margin-bottom: 53px;
      @media #{$size_1000}{
        margin-bottom: 20px;
      }
    }
    .plan_box{
      margin-bottom: 80px;
      @media #{$size_1000}{
        margin-bottom: 40px;
      }
    }
  }
}

.top_plan--02{
  @include section_padding;
  background-color: #fff;
  @media #{$size_1000}{
    padding-left: 0;
    padding-right: 0;
  }
  .inner{
    .h2_title02{
      margin-bottom: 53px;
      @media #{$size_1000}{
        margin-bottom: 20px;
      }
      h2{
        color:#8b865f;
      }
    }
    .plan_box{
      margin-bottom: 80px;
      @media #{$size_1000}{
        margin-bottom: 40px;
      }
    }
  }
}

.plan_box{
  text-align: center;
  .pb_inner{
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    @media #{$size_1000}{
      display: block;
    }
    article{
      width: 33.333333%;
      padding-left: 25px;
      padding-right: 25px;
      box-sizing: border-box;
      @media #{$size_1000}{
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        margin: 0 auto 30px auto;
        padding-left: 0;
        padding-right: 0;
        @media #{$size_1000}{
          margin: 0 auto 0px auto;
        }
      }
      a{
        display: block;
        width: 100%;
        position: relative;
        @media #{$size_1000}{
          .hover_text{
            display: none;
          }
        }
        .pic01{
          img{
            width: 100%;
          }
        }
        .text_box{
          background-color: #fff;
          position: relative;
          .tag{
            background-color: #ec9c9c;
            color:#fff;
            display: inline-block;
            height:24px;
            line-height: 24px;
            position: absolute;
            top: -12px;
            left: 0;
            right: 0;
            margin: auto;
            width: 120px;
            @include fontsize(14);
            @include x03;
          }
          .text_box_inner{
            text-align: left;
            padding: 43px 30px 50px 30px;
            h3{
              @include fontsize(18);
              margin-bottom: 17px;
              @include x03;
            }
          }
        }

      }//a
    }

  }
}

.top_plan--02,
.plan_list_box01--02{
  .plan_box{
    text-align: center;
    .pb_inner{
      margin: 0 auto;
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        display: block;
      }
      article{
        width: 33.333333%;
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
        @media #{$size_1000}{
          padding-left: 15px;
          padding-right: 15px;
          width: 100%;
          margin: 0 auto 30px auto;
          padding-left: 0;
          padding-right: 0;
          @media #{$size_1000}{
            margin: 0 auto 0px auto;
          }
        }
        a{
          display: block;
          width: 100%;
          position: relative;
          @media #{$size_1000}{
            .hover_text{
              display: none;
            }
          }
          .pic01{
            img{
              width: 100%;
            }
          }
          .text_box{
            background-color: #f7f7f7;
            position: relative;
            .tag{
              background-color: #aba786;
              color:#fff;
              display: inline-block;
              height:24px;
              line-height: 24px;
              position: absolute;
              top: -12px;
              left: 0;
              right: 0;
              margin: auto;
              width: 120px;
              @include fontsize(14);
            }
            .text_box_inner{
              text-align: left;
              padding: 43px 30px 50px 30px;
              h3{
                @include fontsize(18);
                margin-bottom: 17px;
              }
            }
          }

        }//a
      }

    }
  }
}

//フォトギャラリー
.top_photo{
  padding-top: 120px;
  box-sizing: border-box;
  @include bcg01;
  @media #{$size_1000}{
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .inner{
    .h2_title02{
      margin-bottom: 53px;
      @media #{$size_1000}{
        margin-bottom: 20px;
      }
    }
    .box01{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media #{$size_700}{
        display: block;
      }
      .box02{
        width: 50%;
        @media #{$size_800}{
          width: 100%;
        }
        a{
          display: block;
          position: relative;
          z-index: 0;
          @media #{$size_1000}{
            padding:15px 0px;
            box-sizing: border-box;
          }

          p{
            &:first-child{
              img{
                width: 100%;
              }
            }
            &:nth-child(2){
              @media #{$size_1000}{
                top:auto;
                bottom: 30px;
                left: 60px;
                height: 30px;
                line-height: 30px;
                display: inline-block;
                opacity: 1;
                width: 250px;
                transform: scale(1);
                position: static;
                @include bcg01;
                color:#252525;
                width: 100%;
                span{
                  @include fontsize(16);

                }
              }
            }
          }//p

        }
      }
    }
  }
}//top_photo

.top_report{
  @include section_padding;
  @include bcg01;
  text-align: center;
  @media #{$size_1000}{
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 0;
    padding-right: 0;
  }
  .inner{
    .h2_title02{
      margin-bottom: 53px;
      @media #{$size_1000}{
        margin-bottom: 20px;
      }
    }
    max-width: 1200px;
    margin: 0 auto;
    .box01,.box02{
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        display: block;
      }
      article{
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
        margin-bottom: 50px;
        @media #{$size_1000}{
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 0px;
          .hover_text{
            display: none;
          }
        }
        a{
          display: block;
          position: relative;

          p{
            img{
              width: 100%;
            }
          }
          .text_box{
            background-color: #fff;
            text-align: left;
            padding: 30px 30px 40px 30px;
            h3{
              @include fontsize(18);
              margin-bottom: 17px;
              @include lora;
            }
            p{
              @include x03;
            }
          }
        }
      }
    }//box01
    .box01{
      article{
        width: 50%;
        @media #{$size_1000}{
          width: 100%;
          margin: 0 auto;
        }
        a{

        }
      }
    }

    .box02{
      margin-bottom: 30px;
      @media #{$size_1000}{
        margin-bottom: 0;
      }
      article{
        width: 33.3333333%;
        @media #{$size_1000}{
          width: 100%;
          margin: 0 auto;
        }
        a{


        }
      }
    }
    .btn_out{
      @media #{$size_1000}{
        margin-top: 30px;
      }
    }
  }
}

.top_report--02{
  @include section_padding;
  background-color: #f7f7f7;
  text-align: center;
  @media #{$size_1000}{
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 0;
    padding-right: 0;
  }
  .inner{
    .h2_title02{
      margin-bottom: 53px;
      @media #{$size_1000}{
        margin-bottom: 20px;
      }
      h2{
        color: #8b865f;
      }
    }
    max-width: 1200px;
    margin: 0 auto;
    .box01,.box02{
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        display: block;
      }
      article{
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
        margin-bottom: 50px;
        @media #{$size_1000}{
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 0px;
          .hover_text{
            display: none;
          }
        }
        a{
          display: block;
          position: relative;

          p{
            img{
              width: 100%;
            }
          }
          .text_box{
            background-color: #fff;
            text-align: left;
            padding: 30px 30px 40px 30px;
            h3{
              @include fontsize(18);
              margin-bottom: 17px;
            }
          }
        }
      }
    }//box01
    .box01{
      article{
        width: 50%;
        @media #{$size_1000}{
          width: 100%;
          margin: 0 auto;
        }
        a{

        }
      }
    }

    .box02{
      margin-bottom: 30px;
      @media #{$size_1000}{
        margin-bottom: 0;
      }
      article{
        width: 33.3333333%;
        @media #{$size_1000}{
          width: 100%;
          margin: 0 auto;
        }
        a{


        }
      }
    }
    .btn_out--gold{
      @media #{$size_1000}{
        margin-top: 30px;
      }
    }
  }
}

.top_information{
  padding-top: 120px;
  padding-bottom: 120px;
  box-sizing: border-box;
  @media #{$size_1000}{
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .inner{
    .h2_title02{
      margin-bottom: 53px;
      @media #{$size_1000}{
        margin-bottom: 20px;
      }
    }
    .btn_out{
      padding-top: 80px;
      @media #{$size_1000}{
        padding-top: 40px;
      }
    }
  }
}

.infomation_box{

  article{
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    &:nth-child(odd){
      @include bcg01;
      text-align: center;
      @include transition0-3;
    }
    &:hover{
      background-color: #ffeded;
      @include transition0-3;
    }
    a{
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      text-align: left;
      max-width: 1000px;
      padding-top: 25px;
      padding-bottom: 25px;
      padding-right: 50px;
      box-sizing: border-box;
      position: relative;
      @media #{$size_1000}{
        display: block;
      }
      .i_box01{
        display: flex;
        flex-direction: row;
        @media #{$size_1000}{
          padding-bottom: 10px;
        }
        .date{
          margin-right: 29px;
          @include x03;
        }
        .tag02{
          margin-right: 29px;
          @include x03;
        }
      }
      .title{
        @include x03;
      }

      .arrow{
        position: absolute;
        right: 0;
        top: calc(50% - 6px);
        width: 8px;
        height: 12px;
        line-height: 12px;
        img{
          width: 100%;
        }
      }
    }
  }
}

.n1126_disney_bnr{
  background-color: #fff7f8;
  padding: 60px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  @media #{$size_1000}{
    padding: 30px 30px;
  }
  .n1126_disney_bnr_inner{
    width: 100%;
    max-width: 1200px;
    a{
      display: block;
      img{
        width: 100%;
      }
    }
  }
}
