@charset "utf-8";



//メインイメージボタン
.top_main_btn{
  text-align: center;
  a{
    background-color: #e08a94;
    color:#fff;
    width: 300px;
    height: 50px;
    line-height: 50px;
    @include fontsize(16);
    letter-spacing: 0.1em;
    display: block;
    margin: 0 auto;
    @include transition01;
    @include x03;
    &:hover{
      background-color: #5d3035;
      @include transition01;
    }
    @media #{$size_1000}{
      width: 250px;
    }
  }
}

.btn_out{//デフォルトボタン
  text-align: center;
  a{
    width: 250px;
    height: 50px;
    line-height: 50px;
    color:#fff;
    border-radius: 25px;
    @include fontsize(16);
    background-color: #e08a94;
    display: block;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 0 auto;
    @include x03;
    &:hover{
      background-color: #5d3035;
      @include transition01;
    }
  }
}

.btn_out--gold{//デフォルトボタン
  text-align: center;
  a{
    width: 250px;
    height: 50px;
    line-height: 50px;
    color:#fff;
    border-radius: 25px;
    @include fontsize(16);
    background-color: #aba67a;
    display: block;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 0 auto;
    @include x03;
    &:hover{
      background-color: #898147;
      @include transition01;
    }
  }
}

.btn_out{//デフォルトボタン
  text-align: center;
  a{
    width: 250px;
    height: 50px;
    line-height: 50px;
    color:#fff;
    border-radius: 25px;
    @include fontsize(16);
    background-color: #e08a94;
    display: block;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 0 auto;
    @include x03;
    &:hover{
      background-color: #5d3035;
      @include transition01;
    }
  }
  input{
    width: 250px;
    height: 50px;
    line-height: 50px;
    color:#fff;
    border-radius: 25px;
    @include fontsize(16);
    background-color: #e08a94;
    display: block;
    text-align: center;
    letter-spacing: 0.1em;
    margin: 0 auto;
    border: none;
    @include x03;
    &:hover{
      background-color: #5d3035;
      @include transition01;
      cursor: pointer;
    }
  }
}



////タイトル
.h2_title01{
  h2{
    @include fontsize(48);
    color:#eab0b7;
    letter-spacing: 0.1em;
    margin-bottom: 27px;
    @media #{$size_1000}{
      @include fontsize(36);
      margin-bottom: 10px;
    }
    @media #{$size_500}{
      @include fontsize(28);
    }
  }
  p{
    @include fontsize(18);
    letter-spacing: 0.2em;
    position: relative;
    display: inline-block;
    @media #{$size_1000}{
      @include fontsize(16);
    }
    &:before,
    &:after{
      content:"";
      position: absolute;
      display: block;
      width:44px;
      height: 1px;
      background-color: #999;
      top: 12px;
      @media #{$size_1000}{
        width:25px;
      }
    }
    &:before{
      left: -65px;
      @media #{$size_1000}{
        left: -35px;
      }
    }
    &:after{
      right: -65px;
      @media #{$size_1000}{
        right: -35px;
      }
    }
  }
}

////タイトル
.h2_title02{
  h2{
    @include fontsize(48);
    color:#eab0b7;
    letter-spacing: 0.1em;
    margin-bottom: 13px;
    @include lora;
    @media #{$size_1000}{
      @include fontsize(36);
    }
    @media #{$size_500}{
      @include fontsize(28);
    }
  }
  p{
    @include fontsize(18);
    letter-spacing: 0.2em;
    position: relative;
    display: inline-block;
    @media #{$size_1000}{
      @include fontsize(16);
    }
    &:before,
    &:after{
      content:"";
      position: absolute;
      display: block;
      width:44px;
      height: 1px;
      background-color: #999;
      top: 12px;
      @media #{$size_1000}{
        width:25px;
      }
    }
    &:before{
      left: -65px;
      @media #{$size_1000}{
        left: -35px;
      }
    }
    &:after{
      right: -65px;
      @media #{$size_1000}{
        right: -35px;
      }
    }
  }
}

//フッターお問い合わせタイトル
.h2_title02--f_contact{
  h2{
    @include fontsize(48);
    color:#fff;
    letter-spacing: 0.1em;
    margin-bottom: 13px;
    @include lora;
    @media #{$size_1000}{
      @include fontsize(36);
    }
    @media #{$size_500}{
      @include fontsize(28);
    }
  }
  p{
    @include fontsize(18);
    letter-spacing: 0.2em;
    position: relative;
    display: inline-block;
    color:#fff;
    &:before,
    &:after{
      content:"";
      position: absolute;
      display: block;
      width:44px;
      height: 1px;
      background-color: #fff;
      top: 12px;
      @media #{$size_1000}{
        width:25px;
      }
    }
    &:before{
      left: -65px;
      @media #{$size_1000}{
        left: -35px;
      }
    }
    &:after{
      right: -65px;
      @media #{$size_1000}{
        right: -35px;
      }
    }
  }
}


///////タグ

.tag02{
  background-color: #ec9c9c;
  color:#fff;
  display: inline-block;
  height:24px;
  line-height: 24px;
  width: 120px;
  @include fontsize(14);
  text-align: center;
}

///////ホバーテキスト
.hover_text{
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9bec5;
  color:#fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0;
  @include transition0-3;
  transform: scale(0);
  span{
    @include fontsize(18);
    letter-spacing: 0.2em;
  }
}
///////ホバーテキスト
.hover_text--gold{
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #bebba6;
  color:#fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0;
  @include transition0-3;
  transform: scale(0);
  span{
    @include fontsize(18);
    letter-spacing: 0.2em;
  }
  @media #{$size_1000}{
    display: none;
  }
}
a{
  &:hover{
    .hover_text,.hover_text--gold{
      opacity: 9;
      @include transition01;
      transform: scale(1);
      span{
        opacity: 1;
      }

    }
  }
}





.detail_title01{
  width: 100%;
  height: 250px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  background-color: #000;
  .inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .detail_title02{
      h2{
        @include fontsize(48);
        color:#fff;
        letter-spacing: 0.1em;
        margin-bottom: 13px;
        @include lora;
        line-height: 1.2;
        @media #{$size_1000}{
          @include fontsize(36);
        }
        @media #{$size_500}{
          @include fontsize(28);
        }
      }
      div{
        @include fontsize(48);
        color:#fff;
        letter-spacing: 0.1em;
        margin-bottom: 13px;
        @include lora;
        line-height: 1.2;
        @media #{$size_1000}{
          @include fontsize(36);
        }
        @media #{$size_500}{
          @include fontsize(28);
        }
      }
      p{
        @include fontsize(18);
        letter-spacing: 0.2em;
        position: relative;
        display: inline-block;
        color:#fff;
        @media #{$size_1000}{
          @include fontsize(16);
        }
        &:before,
        &:after{
          content:"";
          position: absolute;
          display: block;
          width:44px;
          height: 1px;
          background-color: #fff;
          top: 12px;
          @media #{$size_1000}{
            width:25px;
          }
        }
        &:before{
          left: -65px;
          @media #{$size_1000}{
            left: -35px;
          }
        }
        &:after{
          right: -65px;
          @media #{$size_1000}{
            right: -35px;
          }
        }
      }
    }
  }
}

.footer_fair_plan--pt02{
  .pattern_bannaer2{
    background-color: #fff;
  }
}
