@charset "utf-8";



.photo_t_main_anime{
  animation:
  photo_t_main
  2.5s
  cubic-bezier(0.215, 0.61, 0.355, 1)
  forwards;
}

.photo_t_main_anime_remove{
  animation:
  photo_t_main_remove
  2.5s
  cubic-bezier(0.215, 0.61, 0.355, 1)
  forwards;
}

@keyframes pt_body {
  0%{
    transform: translateY(0%) ;
  }
  100%{
    transform: translateY(-500px);
  }
}


@keyframes photo_t_main {
  0%{
    transform: translateY(0%) translateX(0%);
    height: calc(100vh - 70px);
    width: 100%;
  }
  100%{
    transform: translateY(-150%) translateX(150%);
    height: 71px;
    width: 100%;
  }
}

@keyframes photo_t_main_remove {
  0%{
    transform: translateY(-150%) translateX(-150%);
    height: 71px;
    width: 100%;
  }
  100%{
    transform: translateY(-0%) translateX(0%);
    height: calc(100vh - 70px);
    width: 100%;
  }
}


@keyframes ph_box01--01--title_out_h3 {
  0%{
    transform: translateY(100%);
  }
  100%{
    transform: translateY(0) ;
  }
}


@keyframes ph_box01--01--title_out_h3_remove {
  0%{
    transform: translateY(0) ;
  }
  100%{
    transform: translateY(-100%) ;
  }
}



@media #{$size_min1000}{
.ph_box01--01--title_out{

  h3{
    overflow: hidden;
    span{
      display: block;
    }
  }
  p{
    overflow: hidden;
    span{
      display: block;
    }
  }
  .btn_out{
    overflow: hidden;
    a{
      transform-origin: center;
    }
  }
}

.ph_box01--01--title_out_before{
  opacity: 0;
  h3{
    span{
      transform: translateY(100%) ;
    }
  }
  p{
    span{
      transform: translateY(100%) ;
    }
  }
  .btn_out{
    a{
      transform: translateY(100%) ;
    }
  }
}

.ph_box01--01--title_out_animetion{
  display: block;
  z-index: 30;
  h3{
    span{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.2s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  p{
    span{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.5s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  .btn_out{
    a{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.8s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
}

.ph_box01--01--title_out_animetion_remove{
  h3{
    span{
      transform: translateY(0px);
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  p{
    span{
      transform: translateY(0px);
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      0.1s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  .btn_out{
    a{
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      0.2s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }

}

















//パーティータイトル
.ph_box01--02--title_out{
  h3{
    overflow: hidden;
    span{
      display: block;
    }
  }
  p{
    overflow: hidden;
    span{
      display: block;
    }
  }
  .btn_out{
    overflow: hidden;
    a{
      transform-origin: center;
    }
  }
}

.ph_box01--02--title_out_before{
  opacity: 0;
  h3{
    span{
      transform: translateY(100%) ;
    }
  }
  p{
    span{
      transform: translateY(100%) ;
    }
  }
  .btn_out{
    a{
      transform: translateY(100%) ;
    }
  }
}

.ph_box01--02--title_out_animetion{
  display: block;
  position: relative;
  z-index: 30;
  h3{
    span{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.2s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  p{
    span{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.5s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  .btn_out{
    a{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.8s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
}

.ph_box01--02--title_out_animetion_remove{
  h3{
    span{
      transform: translateY(0px);
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  p{
    span{
      transform: translateY(0px);
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      0.1s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  .btn_out{
    a{
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      0.2s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }

}













//料理タイトル
.ph_box02--01--title_out{
  h3{
    overflow: hidden;
    span{
      display: block;
    }
  }
  p{
    overflow: hidden;
    span{
      display: block;
    }
  }
  .btn_out{
    overflow: hidden;
    a{
      transform-origin: center;
    }
  }
}

.ph_box02--01--title_out_before{
  opacity: 0;
  h3{
    span{
      transform: translateY(100%) ;
    }
  }
  p{
    span{
      transform: translateY(100%) ;
    }
  }
  .btn_out{
    a{
      transform: translateY(100%) ;
    }
  }
}

.ph_box02--01--title_out_animetion{
  display: block;
  position: relative;
  z-index: 30;
  h3{
    span{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.2s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  p{
    span{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.5s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  .btn_out{
    a{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.8s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
}

.ph_box02--01--title_out_animetion_remove{
  h3{
    span{
      transform: translateY(0px);
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  p{
    span{
      transform: translateY(0px);
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      0.1s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  .btn_out{
    a{
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      0.2s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }

}























//ドレスタイトル
.ph_box03--02--title_out{
  h3{
    overflow: hidden;
    span{
      display: block;
    }
  }
  p{
    overflow: hidden;
    span{
      display: block;
    }
  }
  .btn_out{
    overflow: hidden;
    a{
      transform-origin: center;
    }
  }
}

.ph_box03--02--title_out_before{
  opacity: 0;
  h3{
    span{
      transform: translateY(100%) ;
    }
  }
  p{
    span{
      transform: translateY(100%) ;
    }
  }
  .btn_out{
    a{
      transform: translateY(100%) ;
    }
  }
}

.ph_box03--02--title_out_animetion{
  display: block;
  z-index: 30;
  h3{
    span{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.2s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  p{
    span{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.5s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  .btn_out{
    a{
      transform: translateY(100%) ;
      animation:
      ph_box01--01--title_out_h3
      0.6s
      0.8s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
}

.ph_box03--02--title_out_animetion_remove{
  h3{
    span{
      transform: translateY(0px);
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  p{
    span{
      transform: translateY(0px);
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      0.1s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }
  .btn_out{
    a{
      animation:
      ph_box01--01--title_out_h3_remove
      0.6s
      0.2s
      cubic-bezier(0.215, 0.61, 0.355, 1)
      forwards;
    }
  }

}

}


























.pt_pic01[data-emergence=hidden] {
  transform: translateX(-100px) translateY(100px);
  transition: 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.pt_pic01[data-emergence=visible] {
  transform: translateX(0%) translateY(0%);
  transition: 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 1;
}

.pt_pic02[data-emergence=hidden] {
  transform: translateX(100px) translateY(100px);
  transition: 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.pt_pic02[data-emergence=visible] {
  transform: translateX(0) translateY(0);
  transition: 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 1;
}
