@charset "utf-8";
.information_main_img{
  background-image: url(/assets/images/information/main_back.jpg);
  background-position: center;
  background-repeat: no-repeat;
}

.infomation_list_box01{
  padding-top: 120px;
  padding-bottom: 120px;
  box-sizing: border-box;
}

.plan_list_box01{
  padding-top: 120px;
  padding-bottom: 120px;
  @include bcg01;
  .plan_box--02{
    .pb_inner{
      flex-wrap: wrap;
      article{
        margin-bottom: 50px;
      }
    }
  }
}

.info_list{
  .part_pagination{
    margin-top: 60px;
  }
}

.infomation_detail_box01{
  @include bcg01;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  text-align: center;
  .inner{
    text-align: center;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    h1{
      @include fontsize(28);
      margin-bottom: 43px;
    }
    .tag{
      width: 130px;
      height: 20px;
      background-color: #f29295;
      color:#fff;
      @include fontsize(14);
      line-height: 20px;
      margin: 0 auto;
      margin-bottom: 10px;
      @include x03;
    }
    .date{
      margin-bottom: 45px;
      @include x03;
    }
  }
  .info_content_box{
    max-width: 1400px;
    background-color: #fff;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    .inner02{
      max-width: 800px;
      margin: 0 auto;
      h2{
        @include fontsize(24);
        padding-bottom: 30px;
        @include x03;
      }
      h3{
        @include fontsize(20);
        padding-bottom: 30px;
        @include x03;
      }
      h4{
        @include fontsize(18);
        padding-bottom: 30px;
        @include x03;
      }
      p{
        padding-bottom: 30px;
        @include x03;
      }
      img{
        max-width: 100%;
        height: auto;
      }
      a{
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        padding-right: 20px;
        color:#fff;
        border-radius: 15px;
        background-color: #e08a94;
        @include x03;
        @include fontsize(13);
        &:hover{
          background-color: #5d3035;
        }
      }

    }
  }
}

.info_detail{
  .btn_out{
    margin-top: 60px;
  }
}
