@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Lora');
//セッティング
@import "base/setting";

@import "base/header";
@import "base/header02";
@import "base/header02_contact";
@import "base/smp_nav";
@import "base/footer";
@import "base/footer_contact";

//パーツ
@import "base/parts";

//トップページ
@import "layout/top/index";

// //アクセス
// @import "layout/access/index";

// //挙式
// @import "layout/ceremony/index";

// //フェア
// @import "layout/fair/index";

// //ご列席者の方へ
// @import "layout/for-guest/index";

//ギャラリー
@import "layout/gallery/index02";

//インフォメーション
@import "layout/information/index";

// //披露宴
// @import "layout/party/index";

//フォトツアー
@import "layout/photo-tours/index";
@import "layout/photo-tours/animation";

//プラン
@import "layout/plan/index";

// //プライバシーポリシー
// @import "layout/privacy-policy/index";

// //ウェディングレポート
// @import "layout/report/index";

//お問い合わせ
@import "layout/contact/index";
