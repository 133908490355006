@charset "utf-8";

.smp_nav_btn{
	position: fixed;
	z-index: 999999;
	top: 23px;
	right: 30px;
	width: 42px;
	height: 14px;
	padding-bottom: 30px;
	opacity: 0.5;
	&:hover{
		opacity: 1;
	}
	@media #{$size_1000}{
		right: 15px;
	}
	.inner{
		position: relative;
		width: 100%;
		height: 100%;
		span{
			display: block;
			width: 38px;
			height: 3px;
			background-color: #000;
			position: absolute;
			&:first-child{
				top: 0;
			}
			&:nth-child(2){
				top: 10px;
        width: 33px;


			}

		}

	}
  p{
    position: absolute;
    @include fontsize(11);
    @include x02;
    top: 19px;
    text-align: center;
    width: 100%;
    letter-spacing: 0.03em;
  }
	&:hover{
		cursor: pointer;
	}
}

//閉じるボタンへ変化するアニメーション
.sp_close_btn{
	.inner{
		animation:nav_bar_out 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s forwards;
		span{
			&:first-child{
				animation:nav_bar_top 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
			}
			&:nth-child(2){
				animation:nav_bar_bottom 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;

			}
		}
	}
	p{
		&:nth-child(2){
			animation:nav_bar_text 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;

		}
	}
}

//閉じるボタンへ変化するアニメーション　上バー
@keyframes nav_bar_top {
	0% {
		top:0px;
		background-color: #000;

	}
	50%{
		top:3px;

	}
	100%{
		top:3px;
		background-color: #fff;

	}
}

//閉じるボタンへ変化するアニメーション　下バー
@keyframes nav_bar_bottom {
	0% {
		top:10px;
		transform: rotate(0deg);
    width: 33px;
		background-color: #000;
	}
	50%{
		top: 3px;
		transform: rotate(0deg);
    width: 38px;
	}
	100%{
		top: 3px;
		transform: rotate(90deg);
    width: 38px;
		background-color: #fff;
	}
}

//閉じるボタンへ変化するアニメーション　テキスト
@keyframes nav_bar_text {
	0% {
		color:#000;
	}
	100%{
		color: #fff;
	}
}

//閉じるボタンへ変化するアニメーション　回転

@keyframes nav_bar_out {
	0% {
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(45deg);
	}
}

//開くボタンへ変化するアニメーション
.sp_open_btn{
	.inner{
		animation:nav_bar_out02 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s forwards;
		transform: rotate(45deg);
		span{
			&:first-child{
				animation:nav_bar_top02 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
				top: 3px;
			}
			&:nth-child(2){
				animation:nav_bar_bottom02 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
				top: 3px;
				transform: rotate(90deg);
        width: 38px;
			}
		}
		p{
			animation:nav_bar_text02 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
		}
	}
}

//開くボタンへ変化するアニメーション　上バー
@keyframes nav_bar_top02 {
	0% {
		top:3px;
		background-color: #fff;
	}

	100%{
		top:0px;
		background-color: #000;

	}
}

//開くボタンへ変化するアニメーション　下バー
@keyframes nav_bar_bottom02 {
	0% {
		top: 3px;
		transform: rotate(90deg);
    width: 38px;
		background-color: #fff;
	}
	100%{
		top: 10px;
		transform: rotate(0deg);
    width: 33px;
		background-color: #000;
	}
}

//閉じるボタンへ変化するアニメーション　テキスト
@keyframes nav_bar_text02 {
	0% {
		color:#fff;
	}
	100%{
		color: #000;
	}
}

//開くボタンへ変化するアニメーション　回転
@keyframes nav_bar_out02 {
	0% {
		transform: rotate(45deg);
	}
	100%{
		transform: rotate(0deg);
	}
}

//スマホナビゲーション
.sp_nav_erea{
	position: relative;
	z-index: 999998;

	.inner{
		z-index: 999998;
		position: fixed;
		right: 0;
		top: 0;
		height: 100vh;
		overflow-y:scroll;
		-webkit-overflow-scrolling: touch;
		background-color: #e08a94;
		padding-top: 65px;
		width: 285px;
		text-align: left;
		transform: translateX(100%);
		@include transition0-3;
		.sp_logo{
			width: 180px;
			margin-left: 30px;
			margin-bottom: 17px;
			img{
				width: 100%;
			}
		}
		.btn_out02{
			a{
				padding-bottom: 16px;
				padding-left: 30px;
				box-sizing: border-box;
				margin-bottom: 20px;
			}
		}
		.sp_ul{
			padding-left: 0px;
			li{
				a{
					color:#fff;
				}
			}
		}
		.ul01{
			margin-bottom: 24px;
			li{
				margin-bottom: 0px;
				a{
					display: block;
					padding-top: 10px;
					padding-bottom: 10px;
					padding-left: 30px;
					box-sizing: border-box;
					@include x03;
				}
			}

		}
		.ul02{
			li{
				@include fontsize(13);
				margin-bottom: 0;
				a{
					padding-top: 7px;
					padding-bottom: 7px;
					padding-left: 30px;
					box-sizing: border-box;
					display: block;
					@include x03;

				}

			}
			padding-bottom: 200px;
		}
		.ul03{
			overflow: hidden;
			padding-bottom: 200px;
			li{
				width: 15px;
				float: left;
				img{
					width: 100%;
				}
				&:first-child{
					margin-right: 20px;
				}
			}
		}
	}
}

.sp_menu02{

	.inner{
		transform: translateX(0%);
		@include transition0-3;
	}

}
