@charset "utf-8";

.header_out{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999997;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  height: 113px;
  @media #{$size_1000}{
    display: none;
  }
  .inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    .left{
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_logo_out{
        a{
          @include transition01;
          opacity: 0.8;
          svg{
            width: 224px;
            path{
              width: 224px;
            }
          }
          &:hover{
            opacity: 1;
            @include transition01;

          }
        }
      }
      .header_logo_out002{
        a{
          @include transition01;
          opacity: 0.8;
          img{
            width: 224px;
          }
          &:hover{
            opacity: 1;
            @include transition01;

          }
        }
      }
      .link01{
        padding-left: 55px;
        @include transition01;
        opacity: 0.8;
        @include x03;
        &:hover{
          opacity: 1;
          @include transition01;

        }
      }
    }
    .right{
      display: flex;
      flex-direction: row;
      align-items: center;
      .tel{
        @include fontsize(20);
        @include lora;
        margin-right: 30px;
        @media #{$size_1000}{
          display: none;
        }
      }

      .access{
        margin-right: 30px;
        @include transition01;
        opacity: 0.8;
        @include x03;
        &:hover{
          opacity: 1;
          @include transition01;

        }
      }
      .header_fair_btn{
        display: block;
        width: 126px;
        height: 40px;
        background-color: #d5ac51;
        line-height: 40px;
        @include x03;
        span{
          color:#fff;
        }
        &:hover{
          background-color: #aa8b3e;
          @include transition0-3;
        }
      }
    }
  }
}
