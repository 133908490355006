@charset "utf-8";

.contact_main_img{
  background-image: url(https://tamahimeden.xsrv.jp/images/contact/main.jpg);
  background-position: center;
  background-repeat: no-repeat;
}

.contact02_main_img{
  background-image: url(https://tamahimeden.xsrv.jp/images/contact/contact02_main.jpg);
  background-position: center;
  background-repeat: no-repeat;
}

.contact_form_out{
  @include section_padding;
  @include bcg01;
  @media #{$size_1000}{
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .inner{
    max-width: 1400px;
    margin: 0 auto;
    background-color: #fff;
    padding-top: 120px;
    padding-bottom: 120px;
    @media #{$size_1000}{
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .inner02{
      max-width: 800px;
      margin: 0 auto;
      .contact_h2_out{
        h2{
          @include fontsize(28);
          margin-bottom: 30px;
          line-height: 1.4;

          @media #{$size_600}{
            @include fontsize(18);
            text-align: left;
          }
        }
        p{
          @include fontsize(16);
          margin-bottom: 40px;
          @include x03;
          br{
            display: none;
          }
          @media #{$size_1000}{
            br{
              display: block;
            }
          }
        }
      }
      table{
        text-align: left;
        @media #{$size_1000}{
          display: block;
        }
        tbody{
          @media #{$size_1000}{
          display: block;
          }
        }
        tr{
          @media #{$size_1000}{
            display: flex;
            flex-direction:column;
          }
          th{
            width: 156px;
            box-sizing: border-box;
            vertical-align: top;
            padding-top: 8px;
            @include x03;
            @media #{$size_1000}{
              display: block;
              padding-bottom: 10px;
              padding-right: 10px;
              box-sizing: border-box;
            }
          }
          .hissuu{
            width: 45px;
            box-sizing: border-box;
            vertical-align: top;
            padding-top: 8px;
            @media #{$size_1000}{
              display: block;
              padding-bottom: 10px;
            }
            span{
              width: 100%;
              border: solid 1px #cc0000;
              color:#cc0000;
              @include fontsize(14);
              display: inline-block;
              text-align: center;
              width: 45px;
              @include x03;
            }
          }
          .input_erea{
            padding-left: 30px;
            box-sizing: border-box;
            padding-bottom: 28px;
            @include fontsize(16);
            @include x03;
            @media #{$size_1000}{
              display: block;
              padding-left: 0;
            }
            .form_sub_text{
              @include fontsize(13);
              padding-top: 3px;
            }
            .form_text_erea{
              width: 100%;
              height: 42px;
              border-radius: 4px;
              outline: none;
              border: solid 1px #d2d2d2;
              padding: 15px;
              box-sizing: border-box;
              min-height: 160px;
              -webkit-appearance: none;
              @include fontsize(16);
            }
            .input_form{
              width: 100%;
              height: 42px;
              border-radius: 4px;
              outline: none;
              border: solid 1px #d2d2d2;
              padding-left: 15px;
              box-sizing: border-box;
              -webkit-appearance: none;
              @include fontsize(16);
            }
            .input_form--02{//郵便番号
              width: 100px;
            }
            .input_form--03{//生年月日
              width:60px;
              padding-left: 10px;
            }
            .form_select{
              width: 100%;
              height: 42px;
              border-radius: 4px;
              outline: none;
              border: solid 1px #d2d2d2;
              padding-left: 15px;
              box-sizing: border-box;
              @include fontsize(16);
            }
            .chek_box_out{
              display: inline-block;
              margin-bottom: 10px;
              margin-right: 15px;
              vertical-align: middle;
              @include fontsize(16);
            }

          }//input_erea
        }//tr
      }//table
      .btn_out{
        padding-top: 40px;
        input{
          -webkit-appearance: none;
        }
      }
    }
  }
}

.kakunin_out{
  table{
    width: 100%;
    tbody{
      @media #{$size_1000}{
        display: block;
        width: 100%;
        box-sizing: border-box;
      }
    }
    tr{
      .evenItemContents{
        @media #{$size_1000}{
          display: block;
          width: 100%;
          box-sizing: border-box;
        }
      }
      td{
        padding-top: 30px;
        padding-bottom: 30px;
        box-sizing: border-box;
        vertical-align: top;
        border-bottom: solid 1px #d2d2d2;
        @include x03;
        font{
          display: none;
        }
        @media #{$size_1000}{
          display: block;
          text-align: left;
        }
        &:first-child{
          width: 200px;
          @media #{$size_1000}{
            width: 100%;
            border-bottom: none;
            padding-bottom: 10px;
          }
        }
        &:nth-child(2){
          width: calc(100% - 200px);
          @media #{$size_1000}{
            width: 100%;
            padding-top: 0;
          }
        }
        .right{
          input{

          }
        }
      }//td
      &:last-child{
        text-align: center;
        td{
          border-bottom: none;
          margin: 0 auto;
          position: relative;
          padding-top: 40px;
          @include x03;
          &:before{
            content:"送信";
            position: absolute;
            left: 0;
            right: 0;
            top: 40px;
            margin:auto;
            z-index: 1;
            display: block;
            color:#fff;
            width: 250px;
            height: 50px;
            line-height: 50px;
            color:#fff;
            border-radius: 25px;
            padding: 0;
            @include fontsize(16);
            background-color: #e08a94;
            display: block;
            text-align: center;
            letter-spacing: 0.1em;
            border: none;
          }
          input{
            position: absolute;
            z-index: 2;
            width: 250px;
            height: 50px;
            top: 40px;
            left: 0;
            right: 0;
            margin:auto;
            opacity: 0;

            &:hover{
              background-color: #5d3035;
              @include transition01;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.error_out{
  table{
    width: 100%;
    tr{
      td{
        text-align: center;
        padding-bottom: 10px;
        color:#cc0000;
        @include x03;
        font{
          color:#cc0000;
        }
      }
    }
  }
}

.thanks_out{
  p{
    line-height: 2;
    @media #{$size_600}{
      @include fontsize(16);
      text-align: left;
      @include x03;
      br{
        display: none;
      }
    }
  }
}
