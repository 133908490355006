@charset "utf-8";
$img_common:'/common/images/common/';
$size_1900: "only screen and (max-width: 1900px)";
$size_1800: "only screen and (max-width: 1800px)";
$size_1700: "only screen and (max-width: 1700px)";
$size_1600: "only screen and (max-width: 1600px)";
$size_1600min: "only screen and (min-width: 1600px)";
$size_1500: "only screen and (max-width: 1500px)";
$size_1400: "only screen and (max-width: 1400px)";
$size_1300: "only screen and (max-width: 1300px)";
$size_1200: "only screen and (max-width: 1200px)";
$size_1160: "only screen and (max-width: 1160px)";
$size_1100: "only screen and (max-width: 1100px)";
$size_1000: "only screen and (max-width: 1000px)";
$size_min1000: "only screen and (min-width: 1000px)";
$size_960: "only screen and (max-width: 960px)";
$size_900: "only screen and (max-width: 900px)";
$size_800: "only screen and (max-width: 800px)";
$size_700: "only screen and (max-width: 700px)";
$size_600: "only screen and (max-width: 600px)";
$size_500: "only screen and (max-width: 500px)";
$size_480: "only screen and (max-width: 480px)";
$size_400: "only screen and (max-width: 400px)";
$size_300: "only screen and (max-width: 300px)";

$size_min_1000: "only screen and (min-width: 1000px)";

// ex @media #{$size_1200}{

//ボタンホバーカラー
$hover_color:#860303;

@mixin fontsize($size: 24, $base: 16) {
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}

@mixin font_gramond400{//regular
	font-family: 'EB Garamond', serif;
	font-weight: 400;
}

@mixin lora{
	font-family: 'Lora', serif;
}

@mixin x01{
	font-family:Ryumin Regular KL;
	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
}

@mixin x02{
	font-family:Ryumin Regular KL;
	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	//font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
}

@mixin x03{
	//font-family:Ryumin Regular KL;
	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
}


@mixin space15{
	box-sizing:border-box;
	padding-left: 15px;
	padding-right: 15px;
}

@mixin space30{
	box-sizing:border-box;
	padding-left: 30px;
	padding-right: 30px;
}

@mixin section_padding{
	padding-top: 120px;
  padding-bottom: 120px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
	@media #{$size_1000}{
		padding-top: 60px;
		padding-bottom: 60px;
	}
}

@mixin bcg01{//背景薄いピンク
	background-color: #fff8f8;
}
$pink01: #e08a94;



@mixin transition01{
	transition: 0.2s cubic-bezier(.165,.84,.44,1);
}
@mixin transition0-3{
	transition: 0.3s cubic-bezier(.165,.84,.44,1);
}
@mixin transitionbtn{
	transition: 3s cubic-bezier(.165,.84,.44,1);
}


@mixin transition02{
	transition: 0.4s cubic-bezier(.165,.84,.44,1);
}
@mixin transition03{
	transition: 5s cubic-bezier(.165,.84,.44,1);
}
