@charset "UTF-8";
.part_pagination--plan{
  padding: 0 0 0px;
}

.plan_box02{
  @media #{$size_1000}{
    margin-bottom: 0 !important;
  }
}

.plan_list_box01--02{
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #fff;
  .plan_box--02{
    .pb_inner{
      flex-wrap: wrap;
      article{
        margin-bottom: 50px;
      }
    }
  }
}
