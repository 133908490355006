@charset "utf-8";

.header_out_detail_contact{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999990;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  height: 70px;
  background-color: #fff;
  @media #{$size_1000}{
    padding-left: 15px;
    padding-right: 15px;
  }
  .inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    .left{
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_logo_out{
        overflow: hidden;
        a{
          line-height: 1.2;
          display: inline-block;
          float: left;
          opacity: 0.8;
          &:hover{
            opacity: 1;
            @include transition01;
          }
          img{
            width: 140px;
            @media #{$size_1000}{
              padding-top: 7px;
              width: 100px;
            }
          }
        }
        .back_to_top{
          float: left;
          @include fontsize(14);
          padding-top: 10px;
          padding-left: 10px;
          a{
            color:#252525;
            &:hover{
              opacity: 0.6;
            }
          }
        }
      }
      .link01{
        padding-left: 55px;
        &:hover{
          opacity: 0.6;
          @include transition01;

        }
      }
    }
    .right{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 0px;
      @media #{$size_1000}{
        padding-right: 0px;
      }
      .tel{
        @include fontsize(18);
        @include lora;
        margin-right: 27px;
        @media #{$size_1000}{
          display: none;
        }
      }
      .access{
        margin-right: 30px;
      }
      .header_fair_btn{
        display: block;
        width: 100px;
        height: 36px;
        background-color: #d5ac51;
        line-height: 36px;
        @include x03;
        @include fontsize(14);
        span{
          color:#fff;
        }
        &:hover{
          background-color: #aa8b3e;
          @include transition0-3;
        }
        @media #{$size_1000}{
          @include fontsize(11);
          width: auto;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
