@charset "UTF-8";

.gallery_contents--dress{
  padding-top: 0;
  .inner{
    max-width: 1600px;
    margin: 0 auto;
    @media #{$size_1600}{
      max-width: 1200px;
    }
    .box01_text{
      padding-bottom: 120px;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box;
      @include x03;
      line-height: 1.8;
      @media #{$size_1000}{
        padding-bottom: 60px;
      }
      p{
        max-width: 600px;
        margin: 0 auto;
        text-align: left;
      }
    }
    .box01{
      display: flex;
      flex-direction: row;
      @media #{$size_1000}{
        display: block;
      }
      p{
        width: 33.3333333%;
        box-sizing: border-box;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 60px;
        @media #{$size_1000}{
          width: 100%;
          //max-width: 500px;
          padding-left: 0;
          padding-right: 0;
          margin: 0 auto 0px auto;
        }
        img{
          width: 100%;
        }

        &:first-child{

        }
        &:nth-child(2){
          padding-top: 120px;
          @media #{$size_1000}{
            padding-top: 0;
          }
        }
        &:nth-child(3){
          padding-top: 40px;
          @media #{$size_1000}{
            padding-top: 0;
          }
        }
      }
    }
  }
}
